.about_container {
    @apply -mt-8;
}
.about_header {
    @apply font-sans text-white text-base font-bold uppercase mb-6;
}
.about_paragraph {
    @apply font-sans text-base text-white text-opacity-80 mb-5;
}

@media (min-width: 768px) {
    .about_container {
        @apply mt-0 px-6;
    }
    .about_paragraph  {
        @apply mb-8 text-sm;
    }
    .about_header {
        @apply hidden;
    }
}