.title_name {
    @apply font-sans text-4xl font-bold tracking-tighter text-white;
}
.title_description {
    @apply font-sans text-xl text-customYellow my-2;
}
.title_paragraph {
    @apply font-sans text-base text-white text-opacity-80;
}

@media (min-width: 768px) {
    .title_name {
        @apply text-5xl;
    }
    .title_description {
        @apply my-4 pt-2 pb-3;
    }
    .title_paragraph {
        @apply w-1/2;
    }
}